<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>
        <v-card-text class="row content mx-0">
        
            <v-row align-content="center" justify="center">
                <v-col cols="10" md="8" lg="6">

                    <v-row>
                        <v-col cols="12">
                            <div class="headline pl-0">Promo Code</div>
                            <v-card-subtitle class="pt-0 pl-0">If you have a promo code you can apply it here</v-card-subtitle>

                            <v-form ref="form">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-model="code" outlined placeholder="Promo Code" 
                                            :rules="[ rules.required ]" @keypress.prevent.enter="loadPromoCode"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-btn block depressed :x-large="!isMobile" color="grey darken-4" class="white--text" :loading="loading" 
                                            @click="loadPromoCode">Apply</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>

                        <v-col cols="12">
                            <div v-if="localPC != null">
                                <v-list color="grey lighten-4" class="rounded-lg">
                                    <v-subheader>Applied promo code</v-subheader>
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ localPC.code }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ localPC.description }}</v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <div class="error--text" @click="removePromoCode">Remove</div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            {{ formatMoney( localPC.amount ) }}
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
                <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
                <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
            </v-btn>

            <v-btn absolute bottom right depressed x-large color="primary" @click="nextStep" :loading="loading">
                <v-icon left>mdi-arrow-right</v-icon> {{ localPC == null ? 'Continue without promo code' : 'Next' }}
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        code        : '',
        localPC     : null,
        loading     : false,
        isMobile    : false,

        rules       : {
            required    : (v) => !!v || 'required',
        }
    }),
    computed: {
        ...mapGetters({
            promoCode   : 'promoCode'
        })
    },
    mounted() {
        if( this.promoCode != null )
            this.localPC = this.promoCode;
    },
    methods: {
        ...mapActions({
            setPromoCode    : 'setPromoCode'
        }),
        async loadPromoCode() {
            if( !this.$refs.form.validate() )
                return;

            try {
                let api = new API();

                this.loading = true;
                let res = await api.post('/promo-code', {
                    code: this.code
                });
                this.loading = false;
                this.code = '';

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error === true ? 'Error' : 'Warning', res.data.message, res.data.error === true ? 'error' : 'warning' );
                    return;
                }

                this.localPC = res.data.data;
            }
            catch(error) {
                this.mxShowMessage('Error', error, 'error');
                this.loading = false;
            }
            finally {
                this.$refs.form.resetValidation();
            }
        },
        applyPromoCode() {
            if( this.localPC != null )
                this.setPromoCode( this.localPC );
        },
        removePromoCode() {
            this.localPC = null
            this.setPromoCode( null );
        },
        nextStep() {
            this.applyPromoCode();
            this.$router.push( '/package' );
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    }
}
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}
</style>